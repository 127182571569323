/** @jsx jsx */
import { jsx, Box, Flex, Heading, Text } from "theme-ui"
import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

const faqItemSX = {
  width: ["100%", "100%", "47.75%"],
  pb: 4,
}
const faqItemTitleSX = {
  mb: 1,
  color: "navy90",
}

const LearnPage = () => (
  <Layout>
    <SEO title="Home" />
    <Box variant="layout.wrapper.large" sx={{ pb: 5 }}>
      <Flex
        sx={{
          flexDirection: ["column", "column", "column", "row"],
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: ["center", "center", "center", "left"],
        }}
      >
        <Box sx={{ width: ["100%", "100%", "100%", "48%"] }}>
          <Heading as="h1" sx={{ mb: 2 }}>
            What we're all about
          </Heading>
          <Text sx={{ fontSize: 2, fontWeight: "500" }}>
            Adopt-an-Alp is guided towards everyone who loves cheese - the
            makers, sellers, and consumers. The participants have access to
            seasonally made, hand crafted, smallest production Alp cheeses. They
            also are in contact with and learn about the people and animals that
            make it possible for us to enjoy these precious cheeses.
          </Text>
        </Box>
        <Box
          sx={{
            width: ["100%", "100%", "100%", "48%"],
            mt: [4, 4, 4, 0],
            iframe: { width: "100%" },
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/165627617"
            allowfullscreen="allowfullscreen"
            width="480"
            height="340"
            frameborder="0"
          ></iframe>
        </Box>
      </Flex>
    </Box>
    <Box sx={{ bg: "skyBlue", py: 5 }}>
      <Box variant="layout.wrapper.medium" sx={{ py: 2 }}>
        <Box sx={{ mb: 4, py: 2 }}>
          <Heading as="h2" sx={{ color: "navy", mb: 2 }}>
            Why Adopt an Alp?
          </Heading>
          <Text sx={{ color: "navy75" }}>
            Thanks to Adopt-an-alp American cheese lovers have access to truly
            rare cheeses made during summers only on Swiss mountains, the Alp
            cheeses. They also get a glimpse into the everyday lives of the
            animals that contribute the milk and the farmers who craft these
            unique cheeses. This connection leads to a mutual respect and the
            realization that we all need to work together in order to be able
            continuing to enjoy Alp cheese.
          </Text>
        </Box>
        <Box sx={{ mb: 4, py: 2 }}>
          <Heading as="h2" sx={{ color: "navy", mb: 2 }}>
            How can I Adopt an Alp?
          </Heading>
          <Text sx={{ color: "navy75" }}>
            Adopting an Alp is easy. Choose your favorite Alp(s) - you can read
            up on each one on the website, check out the "Who is Who"
            spreadsheet if you prefer the quick way, or contact us to find the
            Alp that suits you best. With picking your Alp we make sure you are
            the only one in your geographical region to represent its cheeses,
            and you tell us how many wheels you want to receive in the Fall.
            That's it.
          </Text>
        </Box>
        <Box sx={{ py: 2 }}>
          <Heading as="h2" sx={{ color: "navy", mb: 2 }}>
            The fun stuff!
          </Heading>
          <Text sx={{ color: "navy75" }}>
            There's some fun and informational action going on between the moment you have chosen your Alp and the one when you are receiving your cheese. Our Facebook and Instagram allow you to follow and stay connected to your Alp, family, herd and cheese. This gives you the opportunity to educate your clientele - or for the end consumers: the ones you will be sharing your wheel with -, build an understanding for this very special type of cheese and create contagious curiosity.
          </Text>
        </Box>
      </Box>
    </Box>
    <Box sx={{ variant: "layout.wrapper.large", py: 5 }}>
      <Heading as="h1" sx={{ textAlign: "center", mb: 5 }}>
        Learn More
      </Heading>
      <Flex
        sx={{
          flexDirection: ["column", "column", "row"],
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            What is transhumance
          </Heading>
          <Text>
            Transhumance is the practice of moving animals and people from one
            grazing ground to another in a seasonal cycle, typically to lowlands
            in the winter and highlands in the summer. It serves many purposes
            both nature and community wise, and its ultimate perk are the
            fabulous Alpage cheeses it lets us enjoy year after year.
          </Text>
        </Box>
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            The impact of COVID-19
          </Heading>
          <Text>
            The COVID-19 crisis has made Adopt-an-Alp more meaningful than ever
            before. Sustainability, integrity, traceability and animal welfare
            is what people demand when it comes to food. Alp Cheeses fulfill all
            these desires and needs. Supporting “Älplers” and keeping
            transhumance alive is an essential task of the after COVID era.
          </Text>
        </Box>
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            The summer of 2023
          </Heading>
          <Text>
          This year customers can choose from 27 different Alps. All of them are seasoned Adopt-an-Alp participants. They have proven to be able to fulfill our requirements of quality and communication skills. We have Alps in all of the 14 Swiss cantons that do transhumance. So you get the whole spectrum of traditions, rituals, and specialties.
          </Text>
        </Box>
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            The Adopt-an-Alp map
          </Heading>
          <Text>
            Switzerland is a small country with an area just short of 16,000 sq miles and about 8.8 mio. people. And, of course, a lot of mountains. <a href="/alp-map.jpg">Our Adopt-an-Alp map</a> will help you locate the various operations and give you additional info besides the Alp profiles and the fact loaded spread sheet <a href="/who-is-who.pdf">“Who-is-Who”</a>.
          </Text>
        </Box>
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            Get educated
          </Heading>
          <Text>
          Our website is packed with helpful and educational information around transhumance, Alpage, transhumance and everything related. Read the articles on specific subjects like traditions, milk, AOP cheeses, etc. as well as scientific studies of different subjects to educate yourself and get a broader insight.
          </Text>
        </Box>
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            The parade of our customers
          </Heading>
          <Text>
            14 customers supported Adopt-an-Alp in its inaugural year 2013. This has changed a lot, and the amount increased almost exponentially. In the last years, the ones with the pandemic included, almost a total of 100 stores, restaurants, clubs, etc. joined the program. Check the <Link to="/customers">current parade of clients.</Link>
          </Text>
        </Box>
        <Box sx={faqItemSX}>
          <Heading as="h4" sx={faqItemTitleSX}>
            Sustainability
          </Heading>
          <Text>
            These days, sustainability, integrity, traceability and animal welfare is what people demand when it comes to food . Alp Cheeses fulfill all these requirements, desires and needs. Supporting “Älplers” and keeping transhumance alive is an essential task - especially after the COVID pandemic
          </Text>
        </Box>
      </Flex>
    </Box>
  </Layout>
)

export default LearnPage
